import { useEffect, useState } from 'react'
import { useLeafletContext } from '@react-leaflet/core'
import config from '../../config/BaseMapConfig'
import { getDayString } from '../utils/fogHeihgtsUtils'

const CustomTileLayer = (props) => {
  const { service, date, time, potentialFogHeights } = props
  const { layerContainer, map } = useLeafletContext()
  const container = layerContainer ?? map
  const day = getDayString(service, date)
  const [url, setUrl] = useState(config.overlay_maps.actual_fog.tile_url + day)

  useEffect(() => {
    if (service === 'Potenziell') {
      const dateKeys = `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`
      const height = potentialFogHeights[dateKeys][time]
      setUrl(`${config.overlay_maps.actual_fog.tile_url + day}&mock_height=${height}`)
    } else {
      setUrl(`${config.overlay_maps.actual_fog.tile_url + day}`)
    }

    const attribution = `${config.overlay_maps.actual_fog.attribution},${config.data_origin.attribution}`

    const tileLayer = L.tileLayer(url, { attribution, opacity: '0.6', tms: 'true' })

    container.attributionControl.setPrefix()
    tileLayer.setZIndex(100)

    container.addLayer(tileLayer)
    return () => {
      container.removeLayer(tileLayer)
    }
  }, [day, container, service, date, time, url, potentialFogHeights])

  return null
}

export default CustomTileLayer
