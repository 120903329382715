import React, { useEffect, useState } from 'react'
import ErrorPage from './components/error/ErrorPage'
import BaseMap from './components/Map/BaseMap'

import config from './config/BaseMapConfig'

function App() {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const [potentialFogHeights, setPotentialFogHeights] = useState(null)
  const [prognoseFogHeights, setPrognoseFogHeights] = useState(null)
  const [date, setDate] = useState(today)
  const [service, setService] = useState('Prognose')

  useEffect(() => {
    try {
      fetch(config.fog_heights_url)
        .then((response) => response.json())
        .then((data) => {
          /* 
        Setting up Prognose Fog Heights
         */
          setPrognoseFogHeights({
            'alpen-voralpen': {
              fog_today: data.included_regions['alpen-voralpen'].fog_today,
              fog_tomorrow: data.included_regions['alpen-voralpen'].fog_tomorrow,
            },
            ostschweiz: {
              fog_today: data.included_regions.ostschweiz.fog_today,
              fog_tomorrow: data.included_regions.ostschweiz.fog_tomorrow,
            },
            tessin: {
              fog_today: data.included_regions.tessin.fog_today,
              fog_tomorrow: data.included_regions.tessin.fog_tomorrow,
            },
            westschweiz: {
              fog_today: data.included_regions.westschweiz.fog_today,
              fog_tomorrow: data.included_regions.westschweiz.fog_tomorrow,
            },
          })
        })

      fetch(config.potential_fog_heights_url)
        .then((response) => response.json())
        .then((data) => {
          setPotentialFogHeights(data)
        })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }, [])

  if (potentialFogHeights === null && prognoseFogHeights === null) {
    return <ErrorPage />
  }

  return (
    <div className="Map-container">
      <BaseMap
        potentialFogHeights={potentialFogHeights}
        setPotenialFogHeights={setPotentialFogHeights}
        prognoseFogHeights={prognoseFogHeights}
        setPrognoseFogHeights={setPrognoseFogHeights}
        date={date}
        setDate={setDate}
        service={service}
        setService={setService}
      />
    </div>
  )
}

export default App
