/* eslint-disable new-cap */
import { useCallback, useEffect, useState, useRef } from 'react'
import { useLeafletContext } from '@react-leaflet/core'
import turf from 'turf'
import polylabel from 'polylabel'

import '../../style/LabelStyle.css'
import RegionsGeoJSON from '../../data/geojson/borders.json'
import {
  getLabelText,
  setupRegionConstants,
  addStyleToLabel,
  removeStyleFromLabel,
  initialStyle,
  selectedStyle,
  createPolygonFromBounds,
} from '../utils/RegionsUtils'
import { getDayString, getFogHeightObject } from '../utils/fogHeihgtsUtils'

const Regions = (props) => {
  const { service, date, time, potentialFogHeights, prognoseFogHeights } = props
  const { layerContainer, map } = useLeafletContext()
  const [zoom, setZoom] = useState(map.getZoom())
  const [position, setPosition] = useState(map.getCenter())
  const regionOverlay = useRef(null)
  const regionLabelOverlay = useRef(null)
  const regionPolygonDict = useRef(null)
  const regionBoundDict = useRef(null)
  const [regions, setregions] = useState(RegionsGeoJSON.features)
  const container = layerContainer ?? map

  const fogHeights = getFogHeightObject(service, date, time, potentialFogHeights, prognoseFogHeights)
  const onMoveEnd = useCallback(() => {
    setPosition(map.getCenter())
  }, [map])

  const onZoomEnd = useCallback(() => {
    setZoom(map.getZoom())
  }, [map])

  useEffect(() => {
    setupBaseRegions((regionLayerGroup) => {
      regionOverlay.current = regionLayerGroup
    })

    setupRegionConstants(regions, (bounds, polygons) => {
      regionBoundDict.current = bounds
      regionPolygonDict.current = polygons
    })

    container.addLayer(regionOverlay.current)
    return () => {
      container.removeLayer(regionOverlay)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const day = getDayString(service, date)
    map.on('moveend', onMoveEnd)
    map.on('zoomend', onZoomEnd)

    createLabel(day, (regionLabelGroup) => {
      regionLabelOverlay.current = regionLabelGroup
    })

    container.addLayer(regionLabelOverlay.current)

    return () => {
      map.off('moveend', onMoveEnd)
      map.off('zoomend', onZoomEnd)
      container.removeLayer(regionLabelOverlay.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service, position, fogHeights])

  const setupBaseRegions = (callback) => {
    const regionLayerGroup = new L.LayerGroup()

    regions.forEach((region) => {
      const regionId = region.id
      const regionLayer = new L.geoJSON(region, initialStyle)
      regionLayer._regionId = regionId

      /* Region Control Behavior */

      regionLayer.on('mouseover', () => {
        regionLayer.setStyle(selectedStyle)
        addStyleToLabel(container, regionId)
      })

      regionLayer.on('mouseout', () => {
        regionLayer.setStyle(initialStyle)
        removeStyleFromLabel(container, regionId)
      })

      regionLayer.on('click', () => {
        map.setView(regionLayer.getBounds().getCenter(), 9)
      })

      regionLayerGroup.addLayer(regionLayer)
    })

    callback(regionLayerGroup)
  }

  function createLabel(day, callback) {
    const regionLabelGroup = new L.LayerGroup()
    const mapBounds = map.getBounds()

    regions.forEach((region) => {
      const regionId = region.id
      const regionBound = regionBoundDict.current[regionId]
      const regionPolygon = regionPolygonDict.current[regionId]
      const labelText = getLabelText(fogHeights, day, regionId)

      if (mapBounds.intersects(regionBound)) {
        const mapCoords = createPolygonFromBounds(map, mapBounds)
        const mapPolygon = turf.multiPolygon(mapCoords)

        let intersectionOfPolygons = null
        let intersectionPolygonCenter = null
        let intersectionCenterLngLat = []
        let label = null

        try {
          intersectionOfPolygons = turf.intersect(mapPolygon, regionPolygon) ?? null

          intersectionPolygonCenter = polylabel(intersectionOfPolygons.geometry.coordinates, 1.0) ?? null

          intersectionCenterLngLat = [intersectionPolygonCenter[1], intersectionPolygonCenter[0]]

          label = L.marker(intersectionCenterLngLat, {
            icon: L.divIcon({
              className: `${regionId} text-labels`,
              html: labelText,
            }),
            zIndexOffset: 1000,
          })
        } catch (e) {
          intersectionCenterLngLat = regionBound.getCenter()

          label = L.marker(intersectionCenterLngLat, {
            icon: L.divIcon({
              className: `${regionId} text-labels`,
              html: labelText,
            }),
            zIndexOffset: 1000,
          })
        }

        label._labelId = regionId
        regionLabelGroup.addLayer(label)
      }
    })
    callback(regionLabelGroup)
  }
  return null
}

export default Regions
