const config = {
  version: '1.0',
  background_maps: {
    swiss_style: {
      name: 'OSM Swiss-Style',
      tile_url: 'http://tile.osm.ch/osm-swiss-style/{z}/{x}/{y}.png',
      attribution_url: 'http://www.openstreetmap.org/copyright',
      attribution_text: 'OpenStreetMap Contributors',
    },
    osm_style: {
      name: 'OpenStreetMap',
      tile_url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution_url: 'http://osm.org/copyright',
      attribution_text: 'OpenStreetMap Contributors',
    },
  },

  overlay_maps: {
    actual_fog: {
      tile_url: 'https://nebelkarte.ch/tiles/{z}/{x}/{y}?time=fog_',
      attribution_text: 'Aktuelle Nebelobergrenze',
      attribution: 'Tiles &copy; <a href="https://nebelkarte.ch/api">Nebelkarte</a> ',
    },
    potential_fog: {
      tile_url: 'https://nebelkarte.ch/tiles/{z}/{x}/{y}?mock_height=850',
      attribution_text: 'Potentielle Nebelobergrenze',
      attribution: 'Tiles &copy; <a href="https://nebelkarte.ch/api">Nebelkarte</a> ',
    },
  },

  data_origin: {
    attribution:
      'Nebeldaten  &copy; <a href="https://www.meteocentrale.ch/de/wetter/freizeitwetter/hochnebelobergrenze.html">MeteoCentrale</a>',
  },

  fog_heights_url: 'https://nebelkarte.geoh.infs.ch/fog_heights/ch',
  potential_fog_heights_url: 'https://nebelkarte.geoh.infs.ch/api/forecast/potential_heights',

  swiss_bounds: [45.73, 5.8, 47.9, 10.6],
  swiss_center: [46.8259, 8.2],

  min_zoom: 8,
  max_zoom: 14,
}

export default config
