import { DomUtil } from 'leaflet'

function createIcon(container, image) {
  const link = DomUtil.create('a', 'controlicon', container)
  link.href = '#'
  link.title = 'Über diese Karte'
  link.innerHTML = `<img class="control-image" src="${image}">`
  return link
}

function isSameDay(d1, d2) {
  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()
}

export { createIcon, isSameDay }
