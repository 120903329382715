/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'

const weekday = new Array(7)
weekday[0] = 'So'
weekday[1] = 'Mo'
weekday[2] = 'Di'
weekday[3] = 'Mi'
weekday[4] = 'Do'
weekday[5] = 'Fr'
weekday[6] = 'Sa'

const TextBox = (props) => {
  return (
    <div className="text-box-container hidden">
      <div className="info-text">
        <p>
          Zwischen Anfang Oktober und Anfang M&auml;rz tritt &uuml;ber dem Schweizer Mittelland h&auml;ufig{' '}
          <strong>Hochnebel </strong>auf. Dessen Obergrenze ist nur schwer vorhersagbar und hat eine Streuung von
          mindestens +/- 100m.
        </p>
        <p>Diese (Hoch-)Nebelkarte ist zur Freizeitplanung gedacht und zeigt zwei Arten von Prognosen:</p>
        <p style={{ paddingLeft: '40px' }}>
          <strong>Manuelle Prognose</strong> der Hochnebelobergrenze f&uuml;r ein, bzw. zwei Tage.
        </p>
        <div style={{ paddingLeft: '40px' }}>
          <ul>
            <li>Aktualisierung f&uuml;r den heutigen Tag ca um 8 Uhr.</li>
            <li>Aktualisierung f&uuml;r heute und morgen ca um 20 Uhr.</li>
          </ul>
        </div>
        <p>
          Die Angaben sind in vier Regionen aufgeteilt: Alpen-Voralpen, Ostschweiz, Tessin, Westschweiz. Sie werden von
          Meteorologen bestimmt.
        </p>
        <p style={{ paddingLeft: '40px' }}>
          <strong>Potenzielle Prognose</strong> der Hochnebelobergrenze f&uuml;r sechs Tage:
        </p>
        <div style={{ paddingLeft: '40px' }}>
          <ul>
            <li>Aktualisierung st&uuml;ndlich f&uuml;r die kommenden sechs Tage in Drei-Stunden-Gruppen.</li>
          </ul>
        </div>
        <p>
          Diese Angaben gelten f&uuml;r die gesamte Schweiz und ignorieren weitere regionale Effekte. Sie beruhen auf
          einem statistischen auf dem Luftdruck basierenden Prognoseverfahren.
        </p>
        <p>
          Diese Webapplikation ist vom{' '}
          <strong>Geometa Lab des Instituts f&uuml;r Software am OST Campus Rapperswil </strong>entwickelt worden und
          wird bis auf weiteres und ohne Gew&auml;hr frei zur Verf&uuml;gung gestellt. Deren Nutzung erfolgt auf eigenes
          Risiko. Die Wetterdaten werden freundlicherweise von der <strong>MeteoGroup Schweiz AG </strong>
          bereitgestellt.
        </p>
      </div>
    </div>
  )
}
export default TextBox

/* export default ServiceDateDisplay

import { useLeafletContext } from '@react-leaflet/core'
import L from 'leaflet'
import { useEffect } from 'react'
import { INFOTEXT } from './mapControlUtils'
import '../../style/ControlStyle.css'

const TextBox = (props) => {
  const context = useLeafletContext()

  const BaseText = L.Control.extend({
    onAdd(map) {
      const container = L.DomUtil.create(
        'div',
        ` text-box-container leaflet-control leaflet-bar fitbounds ${props.hidden ? 'hidden' : ''}`,
      )

      // const textBox = setTextBox(container)
      const textBox = setTextBox(container)

      return container
    },

    onRemove(map) {},
  })

  const setTextBox = (container) => {
    const textBox = L.DomUtil.create('div', props.className, container)

    if (props.info) {
      L.DomUtil.addClass(container, 'margin-bottom')
      textBox.innerHTML = INFOTEXT
    }
    return textBox
  }

  useEffect(() => {
    const container = context.map

    const baseControl = new BaseText({ position: props.position })
    container.addControl(baseControl)

    return () => {
      container.removeControl(baseControl)
    }
  })

  return null
}

export default TextBox */
