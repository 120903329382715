import { createControlComponent } from '@react-leaflet/core'
import { Control, DomUtil } from 'leaflet'
import flatpickr from 'flatpickr'
import { German } from 'flatpickr/dist/l10n/de'
import dateImage from '../../data/img/clock.png'
import '../../style/ControlStyle.css'
import 'flatpickr/dist/flatpickr.css'
import { createIcon } from '../utils/ControlUtils'

const createBaseControl = (props) => {
  const {
    position = null,
    service = null,
    setService = null,
    date = null,
    setDate = null,
    setTime = null,
    time = null,
    prognoseFogHeights,
  } = props

  function setButtonsInactive() {
    const buttonContainer = document.getElementsByClassName('time-button-container')

    Array.from(buttonContainer[0].children).forEach((btn) => {
      // eslint-disable-next-line no-param-reassign
      btn.classList.remove('button-active')
    })
  }

  function getTimePicker(dateTimePicker) {
    const buttonContainer = document.createElement('div')
    buttonContainer.className = 'time-button-container'

    const times = ['00', '03', '06', '09', '12', '15', '18', '21']
    times.forEach((ele) => {
      const button = document.createElement('button')
      button.className = `time-button ${time === ele ? 'button-active' : ''}`

      button.innerHTML = `${ele}:00`
      button.value = ele
      button.hidden = true

      button.onclick = () => {
        setTime(button.value)
        setButtonsInactive()
        if (!button.classList.contains('button-active')) {
          button.classList.add('button-active')
        }
      }
      buttonContainer.appendChild(button)
    })

    const container = document.createElement('div')
    const buttonTitle = document.createElement('div')
    buttonTitle.innerHTML = 'Zeit:'
    buttonTitle.hidden = true
    buttonTitle.className = 'button-title'
    container.appendChild(buttonTitle)
    container.appendChild(buttonContainer)

    return container
  }

  function getServicePicker(dateTimePicker, timePicker) {
    const buttonContainer = document.createElement('div')
    buttonContainer.className = 'service-button-container'

    const button = document.createElement('button')
    button.className = `service-button ${service === 'Prognose' ? 'button-active' : 'button-passive'}`

    const button1 = document.createElement('button')
    button1.className = `service-button ${service === 'Potenziell' ? 'button-active' : 'button-passive'}`
    button.innerHTML = 'Prognose'
    button.onclick = () => {
      setService('Prognose')

      const activeDays = getActiveDays('Prognose')

      /*      
      if (date.valueOf() !== activeDays[0].valueOf() && date.valueOf() !== activeDays[1].valueOf()) {
        console.log('reset date')
        dateTimePicker.setDate(activeDays[0])
        setDate(activeDays[0])
      } */
      setDate(activeDays[0])
      dateTimePicker.setDate(activeDays[0])
      dateTimePicker.set('minDate', activeDays[0])
      dateTimePicker.set('maxDate', activeDays[activeDays.length - 1])

      dateTimePicker.redraw()

      button.classList.add('button-active')
      button.classList.remove('button-passive')

      button1.classList.add('button-passive')
      button1.classList.remove('button-active')

      Array.from(timePicker.children).forEach((div) => {
        // eslint-disable-next-line no-param-reassign
        div.hidden = true
        if (div.children) {
          Array.from(div.children).forEach((btn) => {
            // eslint-disable-next-line no-param-reassign
            btn.hidden = true
          })
        }
      })
    }

    button1.innerHTML = 'Potenziell'
    button1.onclick = () => {
      setService('Potenziell')

      const activeDays = getActiveDays('Potenziell')

      dateTimePicker.set('minDate', activeDays[0])
      dateTimePicker.set('maxDate', activeDays[activeDays.length - 1])

      // dateTimePicker.redraw()

      button1.classList.add('button-active')
      button1.classList.remove('button-passive')

      button.classList.add('button-passive')
      button.classList.remove('button-active')

      Array.from(timePicker.children).forEach((div) => {
        // eslint-disable-next-line no-param-reassign
        div.hidden = false
        if (div.children) {
          Array.from(div.children).forEach((btn) => {
            // eslint-disable-next-line no-param-reassign
            btn.hidden = false
          })
        }
      })
    }

    buttonContainer.appendChild(button)
    buttonContainer.appendChild(button1)

    const container = document.createElement('div')
    const buttonTitle = document.createElement('div')
    buttonTitle.innerHTML = 'Service:'
    buttonTitle.className = 'button-title'
    container.appendChild(buttonTitle)
    container.appendChild(buttonContainer)

    return container
  }

  function getDatePicker(dateTimePickerIcon) {
    const activeDays = getActiveDays('Prognose')

    const op2 = {
      disableMobile: 'true',
      locale: German,
      dateFormat: 'Y-m-d',
      time_24hr: true,
      defaultDate: date,
      defaultMinute: 0,
      minuteIncrement: 360,
      minDate: activeDays[0],
      maxDate: activeDays[activeDays.length - 1],
      hourIncrement: 3,
      onOpen() {
        const infoTextClass = document.querySelector('[class^="info-text"]').parentElement.classList
        if (infoTextClass.contains('show')) {
          infoTextClass.remove('show')
          infoTextClass.add('hidden')
        }
      },
      onChange: (selectedDates) => {
        setDate(selectedDates[0])
      },
      closeOnSelect: false,
    }
    const dateTimePicker = flatpickr(dateTimePickerIcon, op2)

    return dateTimePicker
  }

  Control.BaseControl = Control.extend({
    onAdd(map) {
      const mainContainer = DomUtil.create('div', 'leaflet-control leaflet-bar fitbounds date-control')
      const link = createIcon(mainContainer, dateImage)
      const datePicker = getDatePicker(link)

      const timePicker = getTimePicker(datePicker)
      const servicePicker = getServicePicker(datePicker, timePicker)
      datePicker.calendarContainer.appendChild(servicePicker)
      datePicker.calendarContainer.appendChild(timePicker)

      return mainContainer
    },

    onRemove(map) {},
  })

  // Decides which days should be available in datePicker
  function getActiveDays(localService) {
    const tomorrow = new Date()
    tomorrow.setHours(0, 0, 0, 0)
    const availableDates = [
      new Date(tomorrow),
      new Date(tomorrow.setDate(tomorrow.getDate() + 1)),
      new Date(tomorrow.setDate(tomorrow.getDate() + 1)),
      new Date(tomorrow.setDate(tomorrow.getDate() + 1)),
      new Date(tomorrow.setDate(tomorrow.getDate() + 1)),
      new Date(tomorrow.setDate(tomorrow.getDate() + 1)),
    ]

    if (localService === 'Prognose') {
      if (
        Object.keys(prognoseFogHeights)
          .map((region) => {
            const prognoseTomorrow = prognoseFogHeights[region].fog_tomorrow

            return prognoseTomorrow
          })
          .every((value) => {
            if (value === null) return true
            return false
          })
      ) {
        return availableDates.slice(0, 1)
      }
      return availableDates.slice(0, 2)
    }
    return availableDates
  }

  return new Control.BaseControl(props)
}

const DateControl = createControlComponent((props) => createBaseControl(props))
export default DateControl
