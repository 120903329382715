import { createControlComponent } from '@react-leaflet/core'
import { Control, DomUtil } from 'leaflet'
import { createIcon } from '../utils/ControlUtils'
import infoImage from '../../data/img/info.png'

const createInfoControl = (props) => {
  const { position = null } = props
  Control.BaseControl = Control.extend({
    onAdd(map) {
      const mainContainer = DomUtil.create('div', 'leaflet-control leaflet-bar fitbounds date-control')
      const link = createIcon(mainContainer, infoImage)

      link.onclick = () => {
        const infoTextClass = document.querySelector('[class^="info-text"]').parentElement.classList

        if (infoTextClass.contains('hidden')) {
          infoTextClass.remove('hidden')
          infoTextClass.add('show')
        } else {
          infoTextClass.remove('show')
          infoTextClass.add('hidden')
        }
      }
      return mainContainer
    },

    onRemove(map) {},
  })
  return new Control.BaseControl(props)
}

const InfoControl = createControlComponent((props) => createInfoControl(props))
export default InfoControl
