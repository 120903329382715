import moment from 'moment/min/moment-with-locales'

function getDayString(service, date) {
  let day = 'today'
  if (service === 'Prognose') {
    if (date < moment().add(1, 'day').endOf('day') && date > moment().endOf('day')) {
      day = 'tomorrow'
    }
  }
  return day
}

function getFogHeightObject(service, date, time, potentialFogHeights, prognoseFogHeights) {
  const dayString = getDayString(service, date)

  const fogHeights = {
    'alpen-voralpen': {
      fog_height: prognoseFogHeights['alpen-voralpen'][`fog_${dayString}`],
    },
    ostschweiz: {
      fog_height: prognoseFogHeights.ostschweiz[`fog_${dayString}`],
    },
    tessin: {
      fog_height: prognoseFogHeights.tessin[`fog_${dayString}`],
    },
    westschweiz: {
      fog_height: prognoseFogHeights.westschweiz[`fog_${dayString}`],
    },
  }

  if (service === 'Potenziell') {
    const dateKeys = `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`
    const height = potentialFogHeights[dateKeys][time]
    Object.keys(fogHeights).map((region) => {
      fogHeights[region].fog_height = height
      return null
    })
  }

  return fogHeights
}
export { getFogHeightObject, getDayString }
