import React, { useEffect, useState } from 'react'
import '../../style/HeaderStyle.css'
import HeaderService from './HeaderService'
import HeaderInfo from './HeaderInfo'
import DateInfo from './DateInfo'
import Logo from './Logo'

const Header = (props) => {
  const { service, day, setDay, prognoseFogHeights, potentialFogHeights, date, time } = props
  useEffect(() => {}, [day, setDay])

  return (
    <div>
      <div className="header-grid-container">
        <div className="grid-item title">
          <a href="/">Nebelkarte+ Schweiz</a>
        </div>
        <div id="info" className="grid-item info ">
          <HeaderInfo
            service={service}
            date={date}
            time={time}
            potentialFogHeights={potentialFogHeights}
            prognoseFogHeights={prognoseFogHeights}
          />
        </div>
        <div className="grid-item logo">
          <Logo />
        </div>
      </div>
    </div>
  )
}

export default Header
