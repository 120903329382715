import turf from 'turf'

const setupRegionConstants = (regions, callback) => {
  const regionBoundsDict = {}
  const regionPolygonsDict = {}
  regions.forEach((region) => {
    const regionId = region.id
    // eslint-disable-next-line new-cap
    const regionBound = new L.geoJson(region).getBounds()
    regionBoundsDict[regionId] = regionBound

    const regionCoords = region.geometry.coordinates
    const regionPolygon = turf.multiPolygon(regionCoords)
    regionPolygonsDict[regionId] = regionPolygon
  })

  callback(regionBoundsDict, regionPolygonsDict)
}

function getLabelText(fogHeights, day, regionId) {
  let labelText = ''
  const dateKey = `fog_${day}`
  const regionHeight = fogHeights[regionId].fog_height
  switch (regionHeight) {
    case null:
      labelText = 'k.A'
      break
    case 0:
      labelText = 'Kein Nebel'
      break
    default:
      labelText = `${regionHeight.toString()} m.ü.M`
      break
  }
  return labelText
}

function addStyleToLabel(container, regionId) {
  let label = null
  Object.values(container._layers).forEach((layer) => {
    if (layer._labelId === regionId) {
      label = layer
    }
  })

  const attributesNodeMap = label._icon.attributes
  const classes = `${attributesNodeMap.getNamedItem('class').nodeValue} text-labels-hover-effect`
  attributesNodeMap.getNamedItem('class').nodeValue = classes
}

function removeStyleFromLabel(container, regionId) {
  let label = null
  Object.values(container._layers).forEach((layer) => {
    if (layer._labelId === regionId) {
      label = layer
    }
  })
  const attributesNodeMap = label._icon.attributes
  attributesNodeMap.getNamedItem('class').nodeValue = 'leaflet-marker-icon text-labels leaflet-zoom-animated'
}

function createPolygonFromBounds(map, latLngBounds) {
  const center = latLngBounds.getCenter()
  const latlngs = []

  latlngs[0] = [latLngBounds.getSouthWest().lat, latLngBounds.getSouthWest().lng] // bottom left
  latlngs[1] = [latLngBounds.getSouth(), center.lng] // bottom center
  latlngs[2] = [latLngBounds.getSouthEast().lat, latLngBounds.getSouthEast().lng] // bottom right
  latlngs[3] = [center.lat, latLngBounds.getEast()] // center right
  latlngs[4] = [latLngBounds.getNorthEast().lat, latLngBounds.getNorthEast().lng] // top right
  latlngs[5] = [latLngBounds.getNorth(), map.getCenter().lng] // top center
  latlngs[6] = [latLngBounds.getNorthWest().lat, latLngBounds.getNorthWest().lng] // top left
  latlngs[7] = [map.getCenter().lat, latLngBounds.getWest()] // center left
  latlngs[8] = [latLngBounds.getSouthWest().lat, latLngBounds.getSouthWest().lng]

  for (let i = 0; i < latlngs.length; i += 1) {
    const temp = latlngs[i][0]
    // eslint-disable-next-line prefer-destructuring
    latlngs[i][0] = latlngs[i][1]
    latlngs[i][1] = temp
  }

  return [[latlngs]]
}

const initialStyle = {
  color: '#c919ba',
  fillOpacity: 0,
  opacity: 0.1,
}

const selectedStyle = {
  color: '#c919ba',
  weight: 1,
  fillOpacity: 0.1,
  opacity: 0.1,
}

export {
  setupRegionConstants,
  getLabelText,
  addStyleToLabel,
  removeStyleFromLabel,
  createPolygonFromBounds,
  initialStyle,
  selectedStyle,
}
