import React from 'react'
import IFS_Logo from '../../data/img/IFS_Logo.gif'

const Logo = () => {
  return (
    <a href="https://www.ost.ch/ifs">
      <img src={IFS_Logo} alt="IFS Logo" />
    </a>
  )
}

export default Logo
