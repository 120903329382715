import React from 'react'
import { getFogHeightObject } from '../utils/fogHeihgtsUtils'

const HeaderInfo = (props) => {
  const { service, date, time, potentialFogHeights, prognoseFogHeights } = props

  const fogHeights = getFogHeightObject(service, date, time, potentialFogHeights, prognoseFogHeights)

  return Object.keys(fogHeights).map((region) => {
    let heightText = ''
    const regionHeight = fogHeights[region].fog_height

    switch (regionHeight) {
      case null:
        heightText = 'k.A.'
        break
      case 0:
        heightText = 'Kein Nebel'
        break
      default:
        heightText = `${regionHeight.toString()} m.ü.M`
        break
    }

    let regionName = region.charAt(0).toUpperCase() + region.slice(1)

    if (region === 'alpen-voralpen') {
      const nameParts = region.split('-')
      regionName = `${nameParts[0].charAt(0).toUpperCase() + nameParts[0].slice(1)}-${nameParts[1]
        .charAt(0)
        .toUpperCase()}${nameParts[1].slice(1)}`
    }

    return (
      <div key={region} className={region} style={{ gridArea: { region } }}>
        <table>
          <tbody>
            <tr>
              <th>
                <strong>{regionName}</strong>
              </th>
            </tr>
            <tr>
              <td>{heightText}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  })
}

export default HeaderInfo
