import React, { useState, useEffect } from 'react'
import { MapContainer, TileLayer, LayersControl } from 'react-leaflet'
import Header from '../Header/Header'
import config from '../../config/BaseMapConfig'
import 'leaflet/dist/leaflet.css'
import '../../style/BaseMapStyle.css'
import RegionsGeoJSON from '../../data/geojson/borders.json'
import { HEADER_HEIGHT } from '../Header/constants'
import Regions from './Regions'
import DateControl from '../MapControl/DateControl'
import ZoomControl from '../MapControl/ZoomControl'
import InfoControl from '../MapControl/InfoControl'
import TextBox from '../MapControl/TextBox'
import CustomTileLayer from './CustomTileLayer'
import ServiceDateDisplay from './ServiceDateDisplay'

const BaseMap = (props) => {
  const {
    potentialFogHeights,
    // setPotenialFogHeights,
    prognoseFogHeights,
    // setPrognoseFogHeights,
    date,
    setDate,
    service,
    setService,
  } = props

  const [swissCenter, setSwissCenter] = useState(config.swiss_center)
  const [zoom, setZoom] = useState(config.min_zoom)
  const [regions, setregions] = useState(RegionsGeoJSON.features)
  const [time, setTime] = useState('00')

  return (
    <div>
      <Header
        service={service}
        date={date}
        potentialFogHeights={potentialFogHeights}
        prognoseFogHeights={prognoseFogHeights}
        time={time}
      />

      <MapContainer
        center={swissCenter}
        zoom={zoom}
        maxZoom={config.max_zoom}
        minZoom={config.min_zoom}
        scrollWheelZoom
        style={{ height: `calc(100vh - ${HEADER_HEIGHT})`, fontSize: '14px' }}
        attributionControl
      >
        <ServiceDateDisplay service={service} date={date} time={time} />
        <TextBox className="info-text" />

        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name={config.background_maps.swiss_style.name}>
            <TileLayer
              attribution={`Map © <a href= ${config.background_maps.swiss_style.attribution_url}>
            ${config.background_maps.swiss_style.attribution_text}</a> `}
              url={config.background_maps.swiss_style.tile_url}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name={config.background_maps.osm_style.name}>
            <TileLayer
              attribution={`Map © <a href=${config.background_maps.osm_style.attribution_url}>
            ${config.background_maps.osm_style.attribution_text}</a>`}
              url={config.background_maps.osm_style.tile_url}
            />
          </LayersControl.BaseLayer>

          <Regions
            service={service}
            date={date}
            regions={regions}
            time={time}
            potentialFogHeights={potentialFogHeights}
            prognoseFogHeights={prognoseFogHeights}
          />
          <CustomTileLayer
            service={service}
            date={date}
            time={time}
            potentialFogHeights={potentialFogHeights}
            prognoseFogHeights={prognoseFogHeights}
          />
          <DateControl
            position="topleft"
            date={date}
            setDate={setDate}
            setTime={setTime}
            time={time}
            service={service}
            setService={setService}
            prognoseFogHeights={prognoseFogHeights}
          />
          <ZoomControl position="topleft" center={swissCenter} />
          <InfoControl position="topleft" />
        </LayersControl>
      </MapContainer>
    </div>
  )
}
export default BaseMap
