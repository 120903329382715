/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'

const weekday = new Array(7)
weekday[0] = 'So'
weekday[1] = 'Mo'
weekday[2] = 'Di'
weekday[3] = 'Mi'
weekday[4] = 'Do'
weekday[5] = 'Fr'
weekday[6] = 'Sa'

const ServiceDateDisplay = (props) => {
  const { date, service, time } = props
  const cDayString = weekday[date.getDay()]
  const cDay = date.getDate()
  const cMonth = date.getMonth() + 1
  const cYear = date.getFullYear()

  const ctime = service === 'Prognose' ? '-' : `${time}:00`

  return (
    <div className="display-container">
      <div className="display-date">
        <table>
          <tbody>
            <tr>
              <th>
                <strong>Datum</strong>
              </th>
            </tr>
            <tr>
              <td>
                {cDayString}, {cDay}.{cMonth}.{cYear}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="display-time">
        <table>
          <tbody>
            <tr>
              <th>
                <strong>Zeit</strong>
              </th>
            </tr>
            <tr>
              <td>{ctime}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="display-service">
        <table>
          <tbody>
            <tr>
              <th>
                <strong>Service</strong>
              </th>
            </tr>
            <tr>
              <td>{service}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ServiceDateDisplay

/* import { useLeafletContext } from '@react-leaflet/core'
import L from 'leaflet'
import { useEffect } from 'react'

const DisplayControl = (props) => {
  const context = useLeafletContext()
  const { service = null, date = null, time = null } = props
  const BaseDisplay = L.Control.extend({
    onAdd(map) {
      const weekday = new Array(7)
      weekday[0] = 'So'
      weekday[1] = 'Mo'
      weekday[2] = 'Di'
      weekday[3] = 'Mi'
      weekday[4] = 'Do'
      weekday[5] = 'Fr'
      weekday[6] = 'Sa'

      const cDayString = weekday[date.getDay()]
      const cDay = date.getDate()
      const cMonth = date.getMonth() + 1
      const cYear = date.getFullYear()

      const ctime = service === 'Prognose' ? '-' : `${time}:00`
      const container = L.DomUtil.create('div', `display leaflet-control leaflet-bar fitbounds `)

      const display = `<div className=" display ">
      <div>
        <table>
          <tbody>
            <tr>
              <th>
                <strong>DATUM</strong>
              </th>
            </tr>
            <tr>
              <td>
                ${cDayString},${cDay}.${cMonth}.${cYear}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <table>
          <tbody>
            <tr>
              <th>
                <strong>ZEIT</strong>
              </th>
            </tr>
            <tr>
              <td>${ctime}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <table>
          <tbody>
            <tr>
              <th>
                <strong>SERVICE</strong>
              </th>
            </tr>
            <tr>
              <td>${service}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>`

      container.innerHTML = display
      return container
    },

    onRemove(map) {},
  })

  useEffect(() => {
    const container = context.map

    const baseControl = new BaseDisplay({ position: props.position })
    container.addControl(baseControl)

    return () => {
      container.removeControl(baseControl)
    }
  }, [date, time, service])

  return null
}

export default DisplayControl
 */
