import { createControlComponent } from '@react-leaflet/core'
import { Control, DomUtil } from 'leaflet'
import { createIcon } from '../utils/ControlUtils'
import swissmapImage from '../../data/img/swissMap.png'

const createZoomControl = (props) => {
  const { position = null, center = null } = props
  Control.BaseControl = Control.extend({
    onAdd(map) {
      const mainContainer = DomUtil.create('div', 'leaflet-control leaflet-bar fitbounds date-control')
      const link = createIcon(mainContainer, swissmapImage)

      link.onclick = () => {
        map.setView(center, 7)
      }
      return mainContainer
    },

    onRemove(map) {},
  })
  return new Control.BaseControl(props)
}

const ZoomControl = createControlComponent((props) => createZoomControl(props))
export default ZoomControl
