import React from 'react'

const ErrorPage = () => {
  return (
    <div>
      <p style={{ textAlign: 'center' }}>404 - Der Nebel ist zu dicht</p>
    </div>
  )
}

export default ErrorPage
